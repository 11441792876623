import React from "react";
import styled from "styled-components";
import AnimatedIconContainer from "../../custom/AnimatedIconContainer";

const MainContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.05);
  padding: 10px 15px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.3) 0px 3px 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const HeadingContainer = styled.p`
  color: white;
  font-size: 1.2rem;
  margin: 0 20px;

  @media (max-width: 950px) {
    font-size: 0%.8rem;
    margin: 0 15px;
  }
`;

const ProjectImage = styled.img`
  height: 50px;
  @media (max-width: 950px) {
    height: 40px;
  }
`;

const GitHubIconContainer = styled.div`
  color: white;
  &:hover {
    cursor: pointer;
  }
`;

function openPage(url) {
  window.open(url, "_blank");
}

function MoreProjectCard({ icon, name, githubLink }) {
  return (
    <MainContainer>
      <ProjectImage src={icon} alt={name} />
      <HeadingContainer>{name}</HeadingContainer>
      <GitHubIconContainer onClick={() => openPage(githubLink)}>
        <AnimatedIconContainer>
          <i className="fab fa-github fa-2x"></i>
        </AnimatedIconContainer>
      </GitHubIconContainer>
    </MainContainer>
  );
}

export default MoreProjectCard;
