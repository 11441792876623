import React, { useState, useEffect } from "react";
import Footer from "../footer/Footer";
import "./LandingPage.css";
import ProfilePhoto from "./assets/arnav.jpeg";
import { TypeWriterEffect } from "../custom/TypeWriterEffect";
import styled from "styled-components";
import ProjectsSlidingPage from "../slidingPages/ProjectsSlidingPage";
import ResumeSlidingPage from "../slidingPages/ResmeSlidingPage";
import AnimatedIconContainer from "../custom/AnimatedIconContainer";
import ReactGA from "react-ga";

const MyName = styled.span`
  font-family: "Montserrat";
  color: ${(props) => props.theme.primaryFontColor};
  font-size: 6rem;
  font-weight: bolder;
`;

const Dot = styled.span`
  font-family: "Montserrat";
  color: ${(props) => props.theme.secondaryFontColor};
  font-size: 5rem;
  font-weight: bolder;
`;

const HelloWorld = styled.p`
  margin: 0;
  padding: 0;
  font-family: "Montserrat";
  color: ${(props) => props.theme.secondaryFontColor};
  font-size: 1.2rem;
  font-weight: bold;
  position: relative;
  top: 35px;
`;

const HomePageText = styled.p`
  text-align: left;
  color: ${(props) => props.theme.primaryFontColor};
  width: 400px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  position: absolute;
  top: 200px;
  margin-left: 0;
  word-spacing: 0.1rem;

  @media (max-width: 950px) {
    top: 150px;
    width: 300px;
  }
`;

const MyPictureContainer = styled.div`
  width: 280px;
  height: 280px;
  border: 2px solid ${(props) => props.theme.primaryFontColor};
  border-radius: 50%;
  display: flex;

  @media (max-width: 950px) {
    visibility: collapse;
    position: absolute;
  }
`;

const ShowProjectsButton = styled.button`
  color: ${(props) => props.theme.projectsButtonFc};
  background-color: ${(props) => props.theme.projectsButtonBackground};
  height: 70px;
  padding: 0 20px;
  border-radius: 0 2em 2em 0;
  font-size: 1.2rem;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  position: absolute;
  bottom: 10%;
  outline: none;
  transition: 0.3s;
  border-style: none;
  /* border-top: 2px solid transparent;
  border-right: 2px solid transparent;
  border-bottom: 2px solid transparent;
  border-image: conic-gradient(red, yellow, lime, aqua, blue, magenta, red);
  border-image-slice: 1; */

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 950px) {
    bottom: 18%;
  }
`;

const ShowResumeButton = styled.button`
  color: ${(props) => props.theme.resumeButtonFc};
  background-color: ${(props) => props.theme.resumeButtonBackground};
  height: 70px;
  padding: 0 20px;
  border-radius: 2em 0 0 2em;
  font-size: 1.2rem;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  position: absolute;
  bottom: 15%;
  right: 0;
  outline: none;
  border-style: none;
  transition: 0.3s;
  /* box-shadow: inset 0 -0.6em 1em -0.35em rgba(0, 0, 0, 0.17),
    inset 0 0.6em 2em -0.3em rgba(255, 255, 255, 0.15),
    inset 0 0 0em 0.05em rgba(255, 255, 255, 0.12); */

  &:hover {
    cursor: pointer;
  }

  &:active {
    /* box-shadow: inset 0 0.6em 2em -0.3em rgba(0, 0, 0, 0.15),
      inset 0 0 0em 0.05em rgba(255, 255, 255, 0.12); */
  }

  @media (max-width: 950px) {
    bottom: 18%;
  }
`;

const words = [
  "programmer",
  "mad scientist",
  "student",
  "developer",
  "programmer",
  "mad scientist",
  "student",
  "developer",
];

function LandingPage(props) {
  const [firstTime, setFirstTime] = useState(true);

  ///////////////////////////// Analytics ///////////////////////////
  useEffect(() => {
    ReactGA.event({
      category: "User",
      action: "Visited Home Page",
    });
  }, []);
  ///////////////////////////// Projects ///////////////////////////

  const [peakProjects, setPeakProjects] = useState(false);
  const [showProjects, setShowProjects] = useState(false);

  const triggerPeakProjects = () => {
    setPeakProjects(true);
  };

  const hidePeakProjects = () => {
    setPeakProjects(false);
  };

  const triggerShowProjects = () => {
    setShowProjects(true);
    hideShowResume();
  };

  const hideShowProjects = () => {
    setShowProjects(false);
  };

  const peakProjectsStyle = {
    transform: peakProjects ? `translateX(2.5em)` : `none`,
    visibility: showProjects ? `collapse` : `visible`,
  };

  ///////////////////////////// sResume ///////////////////////////

  const [peakResume, setPeakResume] = useState(false);
  const [showResume, setShowResume] = useState(false);

  const triggerPeakResume = () => {
    setPeakResume(true);
  };

  const hidePeakResume = () => {
    setPeakResume(false);
  };

  const triggerShowResume = () => {
    setShowResume(true);
    hideShowProjects();
  };

  const hideShowResume = () => {
    setShowResume(false);
  };

  const peakResumeStyle = {
    transform: peakResume ? `translateX(-2.5em)` : `none`,
    visibility: showResume ? `collapse` : `visible`,
  };

  return (
    <div className="landing-page-container">
      <ProjectsSlidingPage
        peakProjects={peakProjects}
        showProjects={showProjects}
        hideShowProjects={hideShowProjects}
      />
      <ResumeSlidingPage
        peakResume={peakResume}
        showResume={showResume}
        hideShowResume={hideShowResume}
      />
      <div className="name-and-photo-container">
        <div className="name-container noselect">
          <HelloWorld className="text-fade-in">Hello world, I am</HelloWorld>
          <MyName className="text-fade-in">arnav</MyName>
          <Dot className="text-fade-in">.</Dot>
          <HomePageText>
            <span style={{ opacity: `85%` }} className="text-fade-in">
              I am a{" "}
            </span>
            <TypeWriterEffect words={words} />
            <br />
            <span style={{ opacity: `85%` }} className="text-fade-in">
              You know that satisfying feeling when "logic" brings everything
              together in an elegant way? That's the feeling I live for and it
              has kept me moving forward. I promise you I am gonna reach the
              moon one day!
            </span>
          </HomePageText>
        </div>
        <MyPictureContainer>
          <img
            src={ProfilePhoto}
            alt="Arnav's photo"
            className="my-picture noselect"
          />
        </MyPictureContainer>
      </div>

      <ShowProjectsButton
        className="noselect"
        onMouseOver={triggerPeakProjects}
        onMouseLeave={hidePeakProjects}
        onClick={triggerShowProjects}
        style={peakProjectsStyle}
      >
        <span style={{ marginRight: 10 }}>projects</span>
        <AnimatedIconContainer>
          <i className="fas fa-chevron-right"></i>
        </AnimatedIconContainer>
      </ShowProjectsButton>

      <ShowResumeButton
        className="noselect"
        onMouseOver={triggerPeakResume}
        onMouseLeave={hidePeakResume}
        onClick={triggerShowResume}
        style={peakResumeStyle}
      >
        <AnimatedIconContainer>
          <i className="fas fa-chevron-left"></i>
        </AnimatedIconContainer>
        <span style={{ marginLeft: 10 }}>resume</span>
      </ShowResumeButton>
      <Footer />
    </div>
  );
}

export default LandingPage;
