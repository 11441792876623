import React from "react";
import styled, { keyframes } from "styled-components";
import AnimatedIconContainer from "./AnimatedIconContainer";

const wipeAnimation = keyframes`
    0% {
        transform: scaleX(0);
        -moz-transform: scaleX(0);
        -webkit-transform: scaleX(0);
        transform-origin: bottom left;
    } 
    50%{
        transform: scaleX(1);
        -moz-transform: scaleX(1);
        -webkit-transform: scaleX(1);
        transform-origin: bottom left;
    }
    51%{
        transform: scaleX(1);
        -moz-transform: scaleX(1);
        -webkit-transform: scaleX(1);
        transform-origin: bottom right;
    }
    100% {
        transform: scaleX(0);
        -moz-transform: scaleX(0);
        -webkit-transform: scaleX(0);
        transform-origin: bottom right;
    }
`;

const TechStackCardDiv = styled.div`
  position: relative;
  display: flex;
  background-color: rgba(256, 256, 256, 0.05);
  height: 30px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.3) 0px 3px 6px;

  &:hover::before {
    animation-name: ${wipeAnimation};
    animation-duration: 0.3s;
    animation-iteration-count: 1;
  }

  &::before {
    border-radius: 10px;
    transform: scaleX(0);
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    inset: 0 0 0 0;
    background: rgb(255, 255, 255, 0.1);
    z-index: -1;
  }

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 950px) {
    height: 30px;
    padding: 5px;
  }
`;

const TechStackImg = styled.img`
  height: 30px;
  width: 30px;
  object-fit: cover;

  @media (max-width: 950px) {
    height: 30px;
    width: 30px;
  }
`;

const TechStackText = styled.span`
  color: ${(props) => props.theme.resumeTabMediumEmFc};
  font-size: 1.1rem;
  font-weight: bold;
  margin: auto 10px auto 10px;

  @media (max-width: 950px) {
    font-size: 0.8rem;
  }
`;

function TechStackCard({ name, icon }) {
  return (
    <TechStackCardDiv>
      <AnimatedIconContainer>
        <TechStackImg src={icon} alt={name + " Icon"} />
      </AnimatedIconContainer>
      <TechStackText>{name}</TechStackText>
    </TechStackCardDiv>
  );
}

export default TechStackCard;
