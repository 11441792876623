import styled from "styled-components";

export const MoreProjectsPageContainer = styled.div`
  background-color: ${(props) => props.theme.projectsBackground};
  width: 100%;
  transition: 0.3s;
  padding: 5% 10%;
  box-sizing: border-box;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

  /* width */
  &::-webkit-scrollbar {
    width: 7px;
    background-color: ${(props) => props.theme.resumeBackground};
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 5px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.scrollBar};
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.scrollBarHover};
  }

  @media (max-width: 950px) {
    /* width */
    &::-webkit-scrollbar {
      width: 5px;
    }
  }
`;

export const PageHeading = styled.h1`
  color: white;
  text-align: center;
  font-size: 3rem;
  margin: 0;
  padding-bottom: 40px;

  @media (max-width: 950px) {
    font-size: 2rem;
    margin: 20px 0 40px 0;
    padding-bottom: 0;
  }
`;

export const LanguageHeading = styled.p`
  color: white;
  font-size: 1.8rem;
  margin: 20px 0 0 0;

  @media (max-width: 950px) {
    font-size: 1.4rem;
  }
`;

export const ProjectsConatiner = styled.div`
  padding-bottom: 40px;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 950px) {
    margin: 10px 0 0 0;
    font-size: 1rem;
    justify-content: center;
  }
`;

export const ProjectCardContainer = styled.div`
  margin: 15px 25px 0 0;

  @media (max-width: 950px) {
    margin: 10px 0 0 0;
  }
`;
