import React from "react";
import styled, { keyframes } from "styled-components";
import UseWindowDimensions from "./UseWindowDimentions";

const wipeAnimation = keyframes`
    0% {
        margin: 5px 0 5px 0;
        transform: scaleX(0);
        -moz-transform: scaleX(0);
        -webkit-transform: scaleX(0);
        transform-origin: bottom left;
    } 
    50%{
        margin: 0 0 0 0;
        transform: scaleX(1);
        -moz-transform: scaleX(1);
        -webkit-transform: scaleX(1);
        transform-origin: bottom left;
    }
    51%{
        margin: 0 0 0 0;
        transform: scaleX(1);
        -moz-transform: scaleX(1);
        -webkit-transform: scaleX(1);
        transform-origin: bottom right;
    }
    65% {
        margin: 0 0 0 0;
    }

    100% {
        margin: 10px 0 10px 0;
        transform: scaleX(0);
        -moz-transform: scaleX(0);
        -webkit-transform: scaleX(0);
        transform-origin: bottom right;
    }
`;

const CardContainerDiv = styled.div`
  position: relative;
  height: 365px;
  width: 310px;
  background-color: rgba(256, 256, 256, 0.05);
  border-radius: 20px;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.3) 0px 3px 6px;
  /* backdrop-filter: blur(5px); */

  &:hover::before {
    animation-name: ${wipeAnimation};
    animation-duration: 0.4s;
    animation-iteration-count: 1;
  }

  &::before {
    border-radius: 20px;
    transform: scaleX(0);
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    inset: 0 0 0 0;
    background: rgb(255, 255, 255, 0.1);
    z-index: -1;
  }

  @media (max-width: 950px) {
    width: 280px;
    height: 370px;
  }
`;

const CardTitle = styled.p`
  width: 270px;
  margin: 10px auto 0 auto;
  text-align: left;
  font-size: 1.3rem;
  font-weight: 500;
  color: ${(props) => props.theme.highEmFc};

  @media (max-width: 950px) {
    width: 240px;
  }
`;

const CardDescription = styled.p`
  text-align: left;
  width: 270px;
  margin: 7px auto 0 auto;
  font-size: 0.95rem;
  color: ${(props) => props.theme.mediumEmFc};

  @media (max-width: 950px) {
    width: 240px;
  }
`;

const CardImg = styled.img`
  width: 280px;
  height: 180px;
  object-fit: cover;
  margin-top: 20px;
  border-radius: 10px;

  @media (max-width: 950px) {
    margin-top: 20px;
    width: 240px;
    height: 180px;
  }
`;

function AcievementCard({ title, imgUrl, cardDescription }) {
  const windowWidth = UseWindowDimensions().width;

  const descriptionStyle = {};

  const titleStyle = {};

  const cardImgStyle = {};

  return (
    <CardContainerDiv>
      <CardImg style={cardImgStyle} src={imgUrl} alt=""></CardImg>
      <CardTitle style={titleStyle}>{title}</CardTitle>
      <CardDescription style={descriptionStyle}>
        {cardDescription}
      </CardDescription>
    </CardContainerDiv>
  );
}

export default AcievementCard;
