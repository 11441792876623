import React from "react";
import styled from "styled-components";

const FeatureCardContainer = styled.div`
  width: 450px;
  padding: 30px 20px 30px 20px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.3) 0px 3px 6px;
  display: flex;

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 950px) {
    padding: 20px 20px 20px 20px;
    width: 300px;
  }
`;

const FeatureImage = styled.img`
  height: 400px;

  @media (max-width: 950px) {
    height: 200px;
  }
`;

const TextContainer = styled.div`
  width: 100%;
  height: 400px;
  margin: 0 0 0 20px;
  display: inline-block;

  @media (max-width: 950px) {
    margin: 0 0 0 10px;
    height: 200px;
  }
`;

const FeatureTitle = styled.p`
  margin: 0 0 0 0;
  color: ${(props) => props.theme.highEmFc};
  font-size: 1.4rem;
  font-weight: bold;

  @media (max-width: 950px) {
    font-size: 1.1rem;
  }
`;

const FeatureDescription = styled.p`
  margin: 20px 0 0 0;
  color: ${(props) => props.theme.mediumEmFc};

  @media (max-width: 950px) {
    margin: 10px 0 0 0;
    font-size: 0.8rem;
  }
`;

function FeatureCard({ img, title, description }) {
  return (
    <FeatureCardContainer>
      <FeatureImage src={img} alt="" />
      <TextContainer>
        <FeatureTitle>{title}</FeatureTitle>
        <FeatureDescription>{description}</FeatureDescription>
      </TextContainer>
    </FeatureCardContainer>
  );
}

export default FeatureCard;
