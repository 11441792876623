import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

const consoleEgg =
  "\n" +
  "##     ## ######## ##    ##          ######## ######## ##       ##        #######  ##      ##          \n" +
  "##     ## ##        ##  ##           ##       ##       ##       ##       ##     ## ##  ##  ##          \n" +
  "##     ## ##         ####            ##       ##       ##       ##       ##     ## ##  ##  ##          \n" +
  "######### ######      ##             ######   ######   ##       ##       ##     ## ##  ##  ##          \n" +
  "##     ## ##          ##             ##       ##       ##       ##       ##     ## ##  ##  ##          \n" +
  "##     ## ##          ##             ##       ##       ##       ##       ##     ## ##  ##  ##          \n" +
  "##     ## ########    ##             ##       ######## ######## ########  #######   ###  ###           \n" +
  "\n" +
  "\n" +
  "\n" +
  "########  ######## ##     ## ######## ##        #######  ########  ######## ########         ##   ###   \n" +
  "##     ## ##       ##     ## ##       ##       ##     ## ##     ## ##       ##     ##       ####    ##  \n" +
  "##     ## ##       ##     ## ##       ##       ##     ## ##     ## ##       ##     ##        ##      ## \n" +
  "##     ## ######   ##     ## ######   ##       ##     ## ########  ######   ########                 ## \n" +
  "##     ## ##        ##   ##  ##       ##       ##     ## ##        ##       ##   ##          ##      ## \n" +
  "##     ## ##         ## ##   ##       ##       ##     ## ##        ##       ##    ##        ####    ##  \n" +
  "########  ########    ###    ######## ########  #######  ##        ######## ##     ##        ##   ###   \n" +
  "\n";

console.log(consoleEgg);
console.log("This website was made by Arnav Dewan");

ReactDOM.render(<App />, document.getElementById("root"));
