import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import TechStackCard from "../custom/TechStackCard";
import parseServerIcon from "./assets/icons/parseServerIcon.png";
import androidIcon from "./assets/icons/androidIcon.png";
import aightMicrobloggingImg from "./assets/aightMicroblogging.jpg";
import aightEventsImg from "./assets/aightEventsImg.jpg";
import libraryCardImageYellowBG from "./assets/libraryCardImageYellowBG.jpg";
import "./MicrobloggingAppPage.css";
import FeatureCard from "../custom/FeatureCard";
import ProjectCard from "../custom/ProjectCard";
import Fade from "react-reveal/Fade";
import aightMicrobloggingLogin from "./assets/featureImgs/aightMicrobloggingLogin.jpg";
import microbloggingAppMockupGifComp from "./assets/coverGifs/microbloggingAppMockupGifComp.gif";

//(props) => props.theme.projectsBackground
const pageBackground = "#1d2025";
const blueColor = "#adebff";

const MicrobloggingAppPageContainer = styled.div`
  background-color: ${pageBackground};
  width: 100%;
  transition: 0.3s;
  padding: 5% 0 5% 0;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

  /* width */
  &::-webkit-scrollbar {
    width: 7px;
    background-color: ${(props) => props.theme.resumeBackground};
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 5px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.scrollBar};
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.scrollBarHover};
  }

  @media (max-width: 950px) {
    /* width */
    &::-webkit-scrollbar {
      width: 5px;
    }
  }
`;

const HeadingBar = styled.div`
  background-color: ${blueColor};
  height: 140px;
  width: 8px;
  display: inline-block;

  @media (max-width: 950px) {
    height: 130px;
  }
`;

const ProjectHeading = styled.span`
  font-size: 3.6rem;
  width: 200px;
  height: 140px;
  display: inline-block;
  color: ${(props) => props.theme.highEmFc};
  margin-left: 15px;

  @media (max-width: 950px) {
    font-size: 2.2rem;
  }
`;

const MicrobloggingAppMockupImg = styled.img`
  width: 500px;
  border-radius: 20px;
  object-fit: cover;
`;

const IntroPagragraph = styled.p`
  color: ${(props) => props.theme.mediumEmFc};
  margin-top: 7%;
`;

const TechStackTitle = styled.p`
  color: ${(props) => props.theme.highEmFc};
  font-size: 2rem;
  padding: 0 55% 0px 10%;
  margin: 3% 0 10px 0;

  @media (max-width: 950px) {
    margin: 40px 0 10px 0;
    padding: 0 10% 0px 10%;
  }
`;

const TechStackCardContainer = styled.div`
  margin: 10px 30px 10px 0;
`;

const FeatureCardContainer = styled.div`
  margin: 10px 30px 10px 0;

  @media (max-width: 950px) {
    margin: 10px 0 10px 0;
  }
`;

const ProjectGoalTitle = styled.p`
  color: ${(props) => props.theme.highEmFc};
  font-size: 2rem;
  padding: 0 55% 0px 10%;
  margin: 5% 0 10px 0;

  @media (max-width: 950px) {
    margin: 40px 0 10px 0;
    padding: 0 10% 0px 10%;
  }
`;

const ProjectGoalText = styled.p`
  color: ${(props) => props.theme.mediumEmFc};
  margin: 20px 30% 0 10%;

  @media (max-width: 950px) {
    margin: 10px 10% 0 10%;
  }
`;

const ThoughtProcessTitle = styled.p`
  color: ${(props) => props.theme.highEmFc};
  font-size: 2rem;
  padding: 0 55% 0 10%;
  margin: 5% 0 10px 0;
`;

const FeaturesTitle = styled.p`
  color: ${(props) => props.theme.highEmFc};
  font-size: 2rem;
  padding: 0 55% 0px 10%;
  margin: 5% 0 10px 0;

  @media (max-width: 950px) {
    margin: 50px 0 0 0;
    padding: 0 10% 0px 10%;
  }
`;

const techStack = [
  { icon: androidIcon, name: "Android java" },
  { icon: parseServerIcon, name: "Parse Server" },
];

function getTechStackCard(item, key) {
  return (
    <TechStackCardContainer key={key}>
      <TechStackCard name={item.name} icon={item.icon} key={key} />
    </TechStackCardContainer>
  );
}

const features = [
  {
    img: aightMicrobloggingLogin,
    title: "Post blogs",
    description:
      "Users can create and post short blogs with a title and description. These blogs can be about daily diaries, workout routines, short stories/poems, and a lot of other content.",
  },
  {
    img: aightMicrobloggingLogin,
    title: "View other blogs / your own blogs",
    description:
      "Users can view all the blogs of the other users in the home tab and they can also view all of their posted blogs on the profile page.",
  },
  {
    img: aightMicrobloggingLogin,
    title: "Sign in or sign up easily",
    description:
      "Users can sign in or sign up easily by entering their details. The passwords are handled by the parse server auth and are safe and secure.",
  },
];

function getFeatureCard(item, key) {
  return (
    <FeatureCardContainer key={key}>
      <FeatureCard
        img={item.img}
        title={item.title}
        description={item.description}
        key={key}
      />
    </FeatureCardContainer>
  );
}

const ShowMoreFeaturesButton = styled.button`
  margin: 10px 0 0 10%;
  color: ${blueColor};
  background-color: ${pageBackground};
  height: 70px;
  padding: 0 20px;
  border-radius: 10px;
  font-size: 1.2rem;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  outline: none;
  border: solid 1px ${blueColor};

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 950px) {
    margin: 20px auto 0 auto;
    font-size: 1rem;
    display: block;
  }
`;

const LessonsLearntTitle = styled.p`
  color: ${(props) => props.theme.highEmFc};
  font-size: 2rem;
  padding: 0 55% 0px 10%;
  margin: 5% 0 10px 0;

  @media (max-width: 950px) {
    margin: 40px 0 0 0;
    padding: 0 10% 0px 10%;
  }
`;

const LessonsLearntText = styled.p`
  color: ${(props) => props.theme.mediumEmFc};
  margin: 20px 30% 0 10%;

  @media (max-width: 950px) {
    margin: 10px 10% 0 10%;
  }
`;

const OtherProjectsTitle = styled.p`
  color: ${(props) => props.theme.highEmFc};
  font-size: 2rem;
  padding: 0 55% 0px 10%;
  margin: 5% 0 10px 0;

  @media (max-width: 950px) {
    margin: 40px 0 10px 0;
    padding: 0 10% 0px 10%;
  }
`;

const OtherProjectsContainer = styled.div`
  margin-right: 10%;
  margin-left: 10%;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */

  @media (max-width: 950px) {
    margin-top: 20px;
    margin-left: 5%;
    margin-right: 5%;
    font-size: 1rem;
    justify-content: center;
  }
`;

const OtherProjectCardContainer = styled.div`
  margin: 20px 40px 30px 0;

  &:hover {
    cursor: pointer;
  }
  @media (max-width: 950px) {
    margin-left: 5%;
    margin-right: 5%;
    font-size: 1rem;
    justify-content: center;
    margin: 0 10px 20px 0;
  }
`;

const projects = [
  {
    title: "Library App",
    imgUrl: libraryCardImageYellowBG,
    cardDescription:
      "Librarians and students can lend/borrow book with just a click.",
    pageUrl: "/library-app",
  },
  {
    title: "Events website",
    imgUrl: aightEventsImg,
    cardDescription:
      "Helping students discover events in colleges around them.",
    pageUrl: "/events-website",
  },
];

const BackToHomeButton = styled.button`
  margin: 10px 0 0 10%;
  color: ${blueColor};
  background-color: ${pageBackground};
  height: 70px;
  padding: 0 20px;
  border-radius: 10px;
  font-size: 1.2rem;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  outline: none;
  border: solid 1px ${blueColor};

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 950px) {
    margin: 20px auto 0 auto;
    font-size: 1rem;
    display: block;
  }
`;

function MicrobloggingAppPage(props) {
  const [showMoreFeatures, setShowMoreFeatures] = useState(false);

  function updateFeatures() {
    if (showMoreFeatures == false) {
      setShowMoreFeatures(true);
    } else {
      setShowMoreFeatures(false);
    }
  }

  function createProjectItem(project, key) {
    return (
      <OtherProjectCardContainer key={key}>
        <ProjectCard
          title={project.title}
          imgUrl={project.imgUrl}
          cardDescription={project.cardDescription}
          pageUrl={project.pageUrl}
          key={key}
        />
      </OtherProjectCardContainer>
    );
  }
  return (
    <MicrobloggingAppPageContainer>
      <div className="microblogging-app-page-head">
        <div className="microblogging-app-page-heading-container">
          <Fade left>
            <div className="microblogging-app-page-title-container">
              <HeadingBar />
              <ProjectHeading>Microblogging App</ProjectHeading>
            </div>
            <IntroPagragraph>
              This app was for a microblogging app hackathon. It is an app that
              allows users to post microblogs for other users to view. Can be
              used by people who like writing short stories or daily diaries or
              post recipes online.
            </IntroPagragraph>
          </Fade>
        </div>
        <Fade right>
          <div className="microblogging-app-page-mockup-conatiner">
            <MicrobloggingAppMockupImg
              src={microbloggingAppMockupGifComp}
              alt="microblogging app mockup"
            />
          </div>
        </Fade>
      </div>
      <Fade left>
        <TechStackTitle>Tech Stack</TechStackTitle>
        <div className="microblogging-app-tech-stack-container">
          {techStack.map(getTechStackCard)}
        </div>
        <ProjectGoalTitle>Project Goal</ProjectGoalTitle>
        <ProjectGoalText>
          The goal of this project was to build a fully functioning
          microblogging app in the given timeframe of 3 days. (I may have
          forgotten to eat my lunch on one of the days because this was an
          interesting project to build ^_^ )
        </ProjectGoalText>
        {/* <ThoughtProcessTitle>Thought Process</ThoughtProcessTitle> */}
        <FeaturesTitle>Features</FeaturesTitle>
        <div className="microblogging-app-features-container">
          {showMoreFeatures
            ? features.map(getFeatureCard)
            : features.slice(0, 2).map(getFeatureCard)}
        </div>
        <ShowMoreFeaturesButton onClick={updateFeatures}>
          {showMoreFeatures ? "Hide extra" : "Show More"}
        </ShowMoreFeaturesButton>
        <LessonsLearntTitle>Lessons Learnt</LessonsLearntTitle>
        <LessonsLearntText>
          As this was our first app development project, we had to learn how to
          structure the project in a production environment. We learned how to
          use the parse server API to store data and authenticate users. We also
          learned how to create custom-looking buttons and complex listviews.
          <br />
          <br />
          Overall, this project came out better than we expected and also won
          the best development award in the hackathon which booted our
          motivation for developing better apps with cleaner code.
        </LessonsLearntText>
        <OtherProjectsTitle>Other Projects</OtherProjectsTitle>
        <OtherProjectsContainer>
          {projects.map(createProjectItem)}
        </OtherProjectsContainer>
        <Link style={{ textDecoration: "none" }} to="/">
          <BackToHomeButton>
            <i class="fas fa-arrow-left"></i> {"   "}Back to homepage
          </BackToHomeButton>
        </Link>
      </Fade>
    </MicrobloggingAppPageContainer>
  );
}

export default MicrobloggingAppPage;
