import "./App.css";
import { useEffect } from "react";
import LandingPage from "./components/pages/LandingPage";
import { ThemeProvider } from "styled-components";
import { ChangeTheme } from "./styles/changeTheme";
import ThemeToggleButton from "./components/buttons/ThemeToggleButton";
import { GlobalStyle, lightTheme, darkTheme } from "./styles/globalStyles";
import ReactGA from "react-ga";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import LibraryAppPage from "./components/projectPages/LibraryAppPage";
import MicrobloggingAppPage from "./components/projectPages/MicrobloggingAppPage";
import EventsWebsitePage from "./components/projectPages/EventsWebsitePage";
import MoreProjectsPage from "./components/projectPages/MoreProjectsPage/MoreProjectsPage";
import UnderDevelopment from "./components/custom/UnderDevelopment";

// import honeyCombLight from "./styles/backgroundSVGs/honeyCombLight.svg";
// style={{ backgroundImage: `url(${honeyCombLight})` }}

ReactGA.initialize("G-XSW03KKW7K");

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const [theme, toggleTheme] = ChangeTheme();
  const themeMode = theme === "light" ? lightTheme : darkTheme;

  return (
    <Router>
      <ThemeProvider theme={themeMode}>
        <GlobalStyle />
        <ScrollToTop />
        <Switch>
          <Route exact path="/">
            <ThemeToggleButton theme={theme} toggleTheme={toggleTheme} />
            <LandingPage />
          </Route>
          <Route exact path="/library-app" component={LibraryAppPage} />
          <Route
            exact
            path="/microblogging-app"
            component={MicrobloggingAppPage}
            // component={UnderDevelopment}
          />
          <Route
            exact
            path="/events-website"
            component={EventsWebsitePage}
            // component={UnderDevelopment}
          />
          <Route
            exact
            path="/more-projects"
            component={MoreProjectsPage}
            // component={UnderDevelopment}
          />
        </Switch>
      </ThemeProvider>
    </Router>
  );
}

export default App;
