import "./MoreProjectsPage.styles.js";
import React from "react";
import styled from "styled-components";
import MoreProjectCard from "./MoreProjectCard";

import {
  MoreProjectsPageContainer,
  PageHeading,
  LanguageHeading,
  ProjectsConatiner,
  ProjectCardContainer,
} from "./MoreProjectsPage.styles.js";

import defaultProjectIcon from "./assets/defaultProjectIcon.svg";
import hirstPaintingImg from "./assets/python/hirstPainting.png";
import pongGameImg from "./assets/python/pongGame.png";
import turtleCrossingImg from "./assets/python/turtleCrossing.png";

const projects = [
  [
    "Python",
    [
      {
        icon: pongGameImg,
        name: "Pong Game",
        link: "https://github.com/arnxv0/python-projects/tree/main/pong-game",
      },
      {
        icon: hirstPaintingImg,
        name: "Hirst Painting",
        link: "https://github.com/arnxv0/python-projects/tree/main/hirst-painting",
      },
      {
        icon: turtleCrossingImg,
        name: "Turtle Crossing",
        link: "https://github.com/arnxv0/python-projects/tree/main/turtle-crossing",
      },
    ],
  ],
  [
    "Flutter",
    [
      {
        icon: defaultProjectIcon,
        name: "Under Development",
        link: "https://www.github.com",
      },
    ],
  ],
  [
    "Android Java",
    [
      {
        icon: defaultProjectIcon,
        name: "Under Development",
        link: "https://www.github.com",
      },
    ],
  ],
  [
    "Go",
    [
      {
        icon: defaultProjectIcon,
        name: "Under Development",
        link: "https://www.github.com",
      },
    ],
  ],
];

function getProjectCard(details, key) {
  return (
    <ProjectCardContainer key={key}>
      <MoreProjectCard
        name={details["name"]}
        icon={details["icon"]}
        githubLink={details["link"]}
      />
    </ProjectCardContainer>
  );
}

// function getProjectSection(details, key) {
//   return (
//     <>
//       <LanguageHeading key={key}>{details[0]}</LanguageHeading>
//       <ProjectsConatiner>{details[1].map(getProjectCard)}</ProjectsConatiner>
//     </>
//   );
// }

let projectSection;

function MoreProjectsPage() {
  return (
    <MoreProjectsPageContainer>
      <PageHeading>More Projects</PageHeading>
      <LanguageHeading>{projects[0][0]}</LanguageHeading>
      <ProjectsConatiner>
        {projects[0][1].map(getProjectCard)}
      </ProjectsConatiner>
      <LanguageHeading>{projects[1][0]}</LanguageHeading>
      <ProjectsConatiner>
        {projects[1][1].map(getProjectCard)}
      </ProjectsConatiner>
      <LanguageHeading>{projects[2][0]}</LanguageHeading>
      <ProjectsConatiner>
        {projects[2][1].map(getProjectCard)}
      </ProjectsConatiner>
      <LanguageHeading>{projects[3][0]}</LanguageHeading>
      <ProjectsConatiner>
        {projects[3][1].map(getProjectCard)}
      </ProjectsConatiner>
      {/* {projects.map(getProjectSection)} */}
    </MoreProjectsPageContainer>
  );
}

export default MoreProjectsPage;
