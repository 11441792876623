import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import TechStackCard from "../custom/TechStackCard";
import libraryCardImageYellowBG from "./assets/libraryCardImageYellowBG.jpg";

import javascriptIcon from "./assets/icons/javascriptIcon.png";
import htmlIcon from "./assets/icons/htmlIcon.png";
import cssIcon from "./assets/icons/cssIcon.png";
import reactIcon from "./assets/icons/reactIcon.png";
import mongodbIcon from "./assets/icons/mongodbIcon.png";
import nodeIcon from "./assets/icons/nodeIcon.png";
import openLinkIcon from "./assets/icons/openLinkIcon.svg";

import aightEventsFeatureImg from "./assets/featureImgs/aightEventsFeatureImg.png";
import aightMicrobloggingImg from "./assets/aightMicroblogging.jpg";
import aightEventsImg from "./assets/aightEventsImg.jpg";
import "./EventsWebsitePage.css";
import ProjectCard from "../custom/ProjectCard";
import Fade from "react-reveal/Fade";
import WebsiteFeatureCard from "../custom/WebsiteFeatureCard";

const EventsWebsitePageContainer = styled.div`
  background-color: ${(props) => props.theme.projectsBackground};
  width: 100%;
  transition: 0.3s;
  padding: 5% 0 5% 0;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

  /* width */
  &::-webkit-scrollbar {
    width: 7px;
    background-color: ${(props) => props.theme.resumeBackground};
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 5px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.scrollBar};
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.scrollBarHover};
  }

  @media (max-width: 950px) {
    /* width */
    &::-webkit-scrollbar {
      width: 5px;
    }
  }
`;

const HeadingBar = styled.div`
  background-color: #ee6360;
  height: 140px;
  width: 8px;
  display: inline-block;
`;

const ProjectHeading = styled.span`
  font-size: 3.6rem;
  width: 200px;
  height: 140px;
  display: inline-block;
  color: ${(props) => props.theme.highEmFc};
  margin-left: 15px;
`;

const EventsWebsitePageMockupImg = styled.img`
  width: 500px;
  border-radius: 20px;
  object-fit: cover;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.3) 0px 3px 6px;
`;

const IntroPagragraph = styled.p`
  color: ${(props) => props.theme.mediumEmFc};
  margin-top: 7%;
`;

const LiveDemoButtonContainer = styled.div`
  margin: 10px 0;
  padding: 0 0 0 10%;
  width: 200px;
`;

const TechStackTitle = styled.p`
  color: ${(props) => props.theme.highEmFc};
  font-size: 2rem;
  padding: 0 55% 0px 10%;
  margin: 3% 0 10px 0;

  @media (max-width: 950px) {
    margin: 40px 0 10px 0;
    padding: 0 10% 0px 10%;
  }
`;

const TechStackCardContainer = styled.div`
  margin: 10px 30px 10px 0;
`;

const FeatureCardContainer = styled.div`
  margin: 10px 30px 10px 0;

  @media (max-width: 950px) {
    margin: 10px 0 10px 0;
  }
`;

const ProjectGoalTitle = styled.p`
  color: ${(props) => props.theme.highEmFc};
  font-size: 2rem;
  padding: 0 55% 0px 10%;
  margin: 5% 0 10px 0;

  @media (max-width: 950px) {
    margin: 40px 0 10px 0;
    padding: 0 10% 0px 10%;
  }
`;

const ProjectGoalText = styled.p`
  color: ${(props) => props.theme.mediumEmFc};
  margin: 20px 30% 0 10%;

  @media (max-width: 950px) {
    margin: 10px 10% 0 10%;
  }
`;

const ThoughtProcessTitle = styled.p`
  color: ${(props) => props.theme.highEmFc};
  font-size: 2rem;
  padding: 0 55% 0 10%;
  margin: 5% 0 10px 0;
`;

const FeaturesTitle = styled.p`
  color: ${(props) => props.theme.highEmFc};
  font-size: 2rem;
  padding: 0 55% 0px 10%;
  margin: 5% 0 10px 0;

  @media (max-width: 950px) {
    margin: 50px 0 0 0;
    padding: 0 10% 0px 10%;
  }
`;

const techStack = [
  { icon: javascriptIcon, name: "Javacript" },
  { icon: htmlIcon, name: "HTML" },
  { icon: cssIcon, name: "CSS" },
  { icon: reactIcon, name: "React js" },
  { icon: mongodbIcon, name: "MongoDB" },
  { icon: nodeIcon, name: "Node js" },
];

function getTechStackCard(item, key) {
  return (
    <TechStackCardContainer key={key}>
      <TechStackCard name={item.name} icon={item.icon} key={key} />
    </TechStackCardContainer>
  );
}

const features = [
  {
    img: aightEventsFeatureImg,
    title: "Students can view info about events",
    description:
      "The student dashboard contains flters to filter events by date, College, and category. Students can view info about events, and register for them. Future scope would be to add a callender feature.",
  },
  {
    img: aightEventsFeatureImg,
    title: "Organisers can add events",
    description:
      "Organisers can add events that are happening in their college.The event details can include date, college, name, etc. future scope is to have an edit feature.",
  },
  {
    img: aightEventsFeatureImg,
    title: "Login with Google",
    description:
      "Students are allowed to login with Google. This would help in implementing the callender feature.",
  },
];

function getFeatureCard(item, key) {
  return (
    <FeatureCardContainer key={key}>
      <WebsiteFeatureCard
        img={item.img}
        title={item.title}
        description={item.description}
        key={key}
      />
    </FeatureCardContainer>
  );
}

const ShowMoreFeaturesButton = styled.button`
  margin: 10px 0 0 10%;
  color: ${(props) => props.theme.secondaryFontColor};
  background-color: ${(props) => props.theme.resumeButtonBackground};
  height: 70px;
  padding: 0 20px;
  border-radius: 10px;
  font-size: 1.2rem;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  outline: none;
  border: solid 1px ${(props) => props.theme.secondaryFontColor};

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 950px) {
    margin: 20px auto 0 auto;
    font-size: 1rem;
    display: block;
  }
`;

const LessonsLearntTitle = styled.p`
  color: ${(props) => props.theme.highEmFc};
  font-size: 2rem;
  padding: 0 55% 0px 10%;
  margin: 5% 0 10px 0;

  @media (max-width: 950px) {
    margin: 40px 0 0 0;
    padding: 0 10% 0px 10%;
  }
`;

const LessonsLearntText = styled.p`
  color: ${(props) => props.theme.mediumEmFc};
  margin: 20px 30% 0 10%;

  @media (max-width: 950px) {
    margin: 10px 10% 0 10%;
  }
`;

const OtherProjectsTitle = styled.p`
  color: ${(props) => props.theme.highEmFc};
  font-size: 2rem;
  padding: 0 55% 0px 10%;
  margin: 5% 0 10px 0;

  @media (max-width: 950px) {
    margin: 40px 0 10px 0;
    padding: 0 10% 0px 10%;
  }
`;

const OtherProjectsContainer = styled.div`
  margin-right: 10%;
  margin-left: 10%;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */

  @media (max-width: 950px) {
    margin-top: 20px;
    margin-left: 5%;
    margin-right: 5%;
    font-size: 1rem;
    justify-content: center;
  }
`;

const OtherProjectCardContainer = styled.div`
  margin: 20px 40px 30px 0;

  &:hover {
    cursor: pointer;
  }
  @media (max-width: 950px) {
    margin-left: 5%;
    margin-right: 5%;
    font-size: 1rem;
    justify-content: center;
    margin: 0 10px 20px 0;
  }
`;

const projects = [
  {
    title: "Library App",
    imgUrl: libraryCardImageYellowBG,
    cardDescription:
      "Librarians and students can lend/borrow book with just a click.",
    pageUrl: "/library-app",
  },
  {
    title: "Microblogging App",
    imgUrl: aightMicrobloggingImg,
    cardDescription:
      "Users can post micro-blogs and look at other people's blogs.",
    pageUrl: "/microblogging-app",
  },
];

const BackToHomeButton = styled.button`
  margin: 10px 0 0 10%;
  color: ${(props) => props.theme.secondaryFontColor};
  background-color: ${(props) => props.theme.resumeButtonBackground};
  height: 70px;
  padding: 0 20px;
  border-radius: 10px;
  font-size: 1.2rem;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  outline: none;
  border: solid 1px ${(props) => props.theme.secondaryFontColor};

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 950px) {
    margin: 20px auto 0 auto;
    font-size: 1rem;
    display: block;
  }
`;

function EventsWebsitePage(props) {
  const [showMoreFeatures, setShowMoreFeatures] = useState(false);

  function updateFeatures() {
    if (showMoreFeatures == false) {
      setShowMoreFeatures(true);
    } else {
      setShowMoreFeatures(false);
    }
  }

  function createProjectItem(project, key) {
    return (
      <OtherProjectCardContainer key={key}>
        <ProjectCard
          title={project.title}
          imgUrl={project.imgUrl}
          cardDescription={project.cardDescription}
          pageUrl={project.pageUrl}
          key={key}
        />
      </OtherProjectCardContainer>
    );
  }
  return (
    <EventsWebsitePageContainer>
      <div className="events-website-page-head">
        <div className="events-website-page-heading-container">
          <Fade left>
            <div className="events-website-page-title-container">
              <HeadingBar />
              <ProjectHeading>Events Website</ProjectHeading>
            </div>
            <IntroPagragraph>
              This was for a class project and was used to win a hackathon. We
              decided to make a website for all the events conducted by various
              universities. This would help students discover more opportunities
              that are happening around them.
            </IntroPagragraph>
          </Fade>
        </div>
        <Fade right>
          <div className="events-website-page-mockup-conatiner">
            <EventsWebsitePageMockupImg
              src={aightEventsImg}
              alt="Events Website mockup"
            />
          </div>
        </Fade>
      </div>
      <Fade left>
        {/* <LiveDemoButtonContainer>
          {getTechStackCard({ icon: openLinkIcon, name: "Live Demo" }, 1)}
        </LiveDemoButtonContainer> */}
        <TechStackTitle>Tech Stack</TechStackTitle>
        <div className="events-website-tech-stack-container">
          {techStack.map(getTechStackCard)}
        </div>
        <ProjectGoalTitle>Project Goal</ProjectGoalTitle>
        <ProjectGoalText>
          As hackathon/event participants ourselves, we often missed out on
          events just because we didn't know they existed unless we had a friend
          who participated in it. To overcome this problem we decided to make a
          website for all the events conducted by various universities. The goal
          was to solve this problem in the given timeframe of 1 month.
        </ProjectGoalText>
        {/* <ThoughtProcessTitle>Thought Process</ThoughtProcessTitle> */}
        <FeaturesTitle>Features</FeaturesTitle>
        <div className="events-website-features-container">
          {showMoreFeatures
            ? features.map(getFeatureCard)
            : features.slice(0, 2).map(getFeatureCard)}
        </div>
        <ShowMoreFeaturesButton onClick={updateFeatures}>
          {showMoreFeatures ? "Hide extra" : "Show More"}
        </ShowMoreFeaturesButton>
        <LessonsLearntTitle>Lessons Learnt</LessonsLearntTitle>
        <LessonsLearntText>
          A few lessons learnt were:
          <br />
          - The CORS policy was a little tricky in the begining but we managed
          to sort it out.
          <br />
          - Having 2 dashboards at the same time was a good challenge.
          <br />
          - Implementation of user data persistance taught us a lot about how
          websites manage states.
          <br />- Google OAuth login was interesting to implement.
        </LessonsLearntText>
        <OtherProjectsTitle>Other Projects</OtherProjectsTitle>
        <OtherProjectsContainer>
          {projects.map(createProjectItem)}
        </OtherProjectsContainer>
        <Link style={{ textDecoration: "none" }} to="/">
          <BackToHomeButton>
            <i class="fas fa-arrow-left"></i> {"   "}Back to homepage
          </BackToHomeButton>
        </Link>
      </Fade>
    </EventsWebsitePageContainer>
  );
}

export default EventsWebsitePage;
