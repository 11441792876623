import React from "react";
import styled from "styled-components";
import AnimatedIconContainer from "../custom/AnimatedIconContainer";
import ProjectCard from "../custom/ProjectCard";
import UseWindowDimensions from "../custom/UseWindowDimentions";
import libraryCardImage from "./assets/libraryCardImage.jpg";
import libraryCardImageYellowBG from "./assets/libraryCardImageYellowBG.jpg";
import hacktoberFestImg from "./assets/hacktoberFest.jpg";
import googleCloudImg from "./assets/googleCloud.png";
import dscWowGif from "./assets/dscWow.gif";
import dscWowImg from "./assets/dscWowImg.jpg";
import dscWebathonImg from "./assets/dscWebathon.jpg";
import aightMicrobloggingImg from "./assets/aightMicroblogging.jpg";
import aightEventsImg from "./assets/aightEventsImg.jpg";
import AchievementCard from "../custom/AchievementCard";
import appSprintImg from "./assets/appSprint.png";
import projectsPageBackground from "./assets/projectsPageBackground.svg";
import { rgba } from "polished";
import { Link } from "react-router-dom";

const ProjectsSlidingPageContainer = styled.div`
  position: absolute;
  top: 0;
  left: -100%;
  height: 100%;
  width: 100%;
  z-index: 100;
  background-color: ${(props) => props.theme.projectsBackground};
  /* background-color: ${(props) =>
    rgba(props.theme.secondaryFontColor, 0.25)}; */
  /* backdrop-filter: blur(9px); */
  transition: 0.3s;
  overflow-y: auto;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

  /* width */
  &::-webkit-scrollbar {
    width: 7px;
    background-color: ${(props) => props.theme.projectsBackground};
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 5px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.scrollBar};
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.scrollBarHover};
  }

  @media (max-width: 950px) {
    overflow-x: hidden;
    /* width */
    &::-webkit-scrollbar {
      width: 5px;
    }
  }
`;

const BackButtonContainer = styled.div`
  position: absolute;
  top: 5%;
  right: 4%;
  height: 200%;

  @media (max-width: 950px) {
    top: 3%;
    right: 20px;
  }
`;

const BackButton = styled.button`
  position: sticky;
  position: -webkit-sticky;
  top: 5%;
  color: ${(props) => props.theme.secondaryFontColor};
  background-color: ${(props) => props.theme.lowEmFc};
  border-radius: 50%;
  height: 70px;
  width: 70px;
  font-family: "Montserrat", sans-serif;
  outline: none;
  border-style: none;

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 950px) {
    position: static;
    height: 50px;
    width: 50px;
  }
`;

////////////////// Projects Section/////////////////////////////

const AllProjectsTitle = styled.p`
  margin: 5% 0 0 19%;
  font-family: "Montserrat";
  color: ${(props) => props.theme.highEmFc};
  font-size: 2rem;
  font-weight: bolder;

  @media (max-width: 950px) {
    margin: 7% 0 30px 10%;
    font-size: 2rem;
  }
`;

const ProjectsDiv = styled.div`
  margin-right: 10%;
  margin-left: 19%;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */

  @media (max-width: 950px) {
    margin-left: 5%;
    margin-right: 5%;
    font-size: 1rem;
    justify-content: center;
  }
`;

const ProjectCardContainer = styled.div`
  margin: 20px 40px 30px 0;

  &:hover {
    cursor: pointer;
  }
  @media (max-width: 950px) {
    margin-left: 5%;
    margin-right: 5%;
    font-size: 1rem;
    justify-content: center;
    margin: 0 10px 20px 0;
  }
`;

const projects = [
  {
    title: "Library App",
    imgUrl: libraryCardImage,
    cardDescription:
      "Librarians and students can lend/borrow book with just a click.",
    pageUrl: "/library-app",
  },
  {
    title: "Events website",
    imgUrl: aightEventsImg,
    cardDescription:
      "Helping students discover events in colleges around them.",
    pageUrl: "/events-website",
  },
  {
    title: "Microblogging App",
    imgUrl: aightMicrobloggingImg,
    cardDescription:
      "Users can post micro-blogs and look at other people's blogs.",
    pageUrl: "/microblogging-app",
  },
];

/////////////////////// Achievements section /////////////////////////////////////

const achievements = [
  {
    title: "Hacktober Fest 2020",
    imgUrl: hacktoberFestImg,
    cardDescription:
      "Participated and received t-shit and stickers for contributing to 4 open source projects",
  },
  {
    title: "Google Cloud",
    imgUrl: googleCloudImg,
    cardDescription:
      "Took various training session on Google Cloud Platform and received t-shirt and stickers",
  },
  {
    title: "DSC WoW Top 20",
    imgUrl: dscWowImg,
    cardDescription:
      "Placed Top 20 in Googles DSC Wow and received t-shirt and stickers",
  },
  {
    title: "Webathon'20 winner",
    imgUrl: dscWebathonImg,
    cardDescription:
      "Won first place in a web development contest held by Developer Students Club by Google from PES University",
  },
  {
    title: "AppSprint'20 winner",
    imgUrl: appSprintImg,
    cardDescription:
      "Won the best development award in a microblogging app making contest held by PES University",
  },
];

const AchievementsTitle = styled.p`
  margin: 4% 0 10px 19%;
  font-family: "Montserrat";
  color: ${(props) => props.theme.highEmFc};
  font-size: 2rem;
  font-weight: bolder;

  @media (max-width: 950px) {
    margin: 7% 0 30px 15%;
    font-size: 2rem;
  }
`;

const AchievementsDiv = styled.div`
  margin-right: 10%;
  margin-left: 19%;
  margin-bottom: 5%;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 950px) {
    margin-left: 5%;
    margin-right: 5%;
    font-size: 1rem;
    justify-content: center;
  }
`;

const AchievementCardContainer = styled.div`
  margin: 20px 40px 30px 0;

  &:hover {
    cursor: pointer;
  }
  @media (max-width: 950px) {
    font-size: 1rem;
    justify-content: center;
    margin: 0 10px 30px 0;
  }
`;

/////////////////////// More projects section /////////////////////////////////////

const MoreProjectsButton = styled.button`
  margin: 20px 0 0 19%;
  color: ${(props) => props.theme.secondaryFontColor};
  background-color: ${(props) => props.theme.resumeButtonBackground};
  height: 70px;
  padding: 0 20px;
  border-radius: 10px;
  font-size: 1.4rem;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  outline: none;
  border: solid 1px ${(props) => props.theme.secondaryFontColor};

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 950px) {
    margin: 20px auto 0 auto;
    font-size: 1rem;
    display: block;
  }
`;

function ProjectsSlidingPage({ peakProjects, showProjects, hideShowProjects }) {
  let peakProjectsStyle;
  let windowWidth = UseWindowDimensions().width;
  if (windowWidth < 950) {
    peakProjectsStyle = {
      transform: showProjects
        ? `translateX(100%)`
        : peakProjects
        ? `translateX(3.2em)`
        : `none`,
    };
  } else {
    peakProjectsStyle = {
      transform: showProjects
        ? `translateX(85%)`
        : peakProjects
        ? `translateX(3.2em)`
        : `none`,
    };
  }

  function createProjectItem(project, key) {
    return (
      <ProjectCardContainer key={key}>
        <ProjectCard
          title={project.title}
          imgUrl={project.imgUrl}
          cardDescription={project.cardDescription}
          pageUrl={project.pageUrl}
          key={key}
        />
      </ProjectCardContainer>
    );
  }

  function createAchievementItem(achievement, key) {
    return (
      <AchievementCardContainer key={key}>
        <AchievementCard
          title={achievement.title}
          imgUrl={achievement.imgUrl}
          cardDescription={achievement.cardDescription}
          key={key}
        />
      </AchievementCardContainer>
    );
  }

  return (
    <ProjectsSlidingPageContainer style={peakProjectsStyle}>
      <AllProjectsTitle>
        {windowWidth > 950 ? "What I have been working on" : "Projects"}
      </AllProjectsTitle>

      <ProjectsDiv> {projects.map(createProjectItem)}</ProjectsDiv>
      <Link style={{ textDecoration: "none" }} to="/more-projects">
        <MoreProjectsButton>
          More projects &nbsp;
          <AnimatedIconContainer>
            <i className="fas fa-chevron-right"></i>
          </AnimatedIconContainer>
        </MoreProjectsButton>
      </Link>
      <BackButtonContainer>
        <BackButton onClick={hideShowProjects}>
          <AnimatedIconContainer>
            <i
              className={
                windowWidth > 950
                  ? "fas fa-chevron-left fa-3x"
                  : "fas fa-chevron-left fa-2x"
              }
            ></i>
          </AnimatedIconContainer>
        </BackButton>
      </BackButtonContainer>
      <AchievementsTitle>
        {windowWidth > 950
          ? "Some achievements worth mentioning"
          : "Achievements"}
      </AchievementsTitle>
      <AchievementsDiv>
        {achievements.map(createAchievementItem)}
      </AchievementsDiv>
    </ProjectsSlidingPageContainer>
  );
}

export default ProjectsSlidingPage;
