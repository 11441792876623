import { useState, useEffect } from "react";

export const ChangeTheme = () => {
  const today = new Date();
  const time = today.getHours();
  const firstTheme = time >= 6 && time <= 21 ? "light" : "dark";
  const [theme, setTheme] = useState("dark");

  const setMode = (theme) => {
    window.localStorage.setItem("theme", theme);
    setTheme(theme);
  };

  const toggleTheme = () => {
    theme === "dark" ? setMode("light") : setMode("dark");
  };

  useEffect(() => {
    const localTheme = window.localStorage.getItem("theme");
    localTheme ? setTheme(localTheme) : setTheme("dark");
  }, []);

  return [theme, toggleTheme];
};
