import React, { useState } from "react";
import styled from "styled-components";
import UseWindowDimensions from "./UseWindowDimentions";
import { Link } from "react-router-dom";

const CardContainerDiv = styled.div`
  position: relative;
`;

const CardTitle = styled.span`
  color: ${(props) => props.theme.highEmFc};
  font-style: italic;
  position: absolute;
  width: 100%;
  margin-top: 130px;
  text-align: center;
  font-size: 1.8rem;
  font-weight: 500;
  z-index: 5;
  transition: transform 0.4s;

  @media (max-width: 950px) {
    margin-top: 85px;
    font-size: 1.5rem;
  }
`;

const CardDescription = styled.span`
  text-align: center;
  color: white;
  position: absolute;
  margin-top: 140px;
  margin-left: 50px;
  width: 300px;
  font-size: 1rem;
  z-index: 5;
  transition: opacity 0.4s;

  @media (max-width: 950px) {
    visibility: hidden;
    z-index: -999;
  }
`;

const CardImg = styled.img`
  height: 300px;
  width: 400px;
  border-radius: 20px;
  filter: brightness(75%);
  transition-property: scale box-shadow filter;
  transition-duration: 0.3s;

  @media (max-width: 950px) {
    height: 200px;
    width: 280px;
  }
`;

const ReadMoreText = styled.span`
  text-align: center;
  color: white;
  position: absolute;
  bottom: 10px;
  width: 100%;
  font-size: 0.8rem;
  z-index: 5;
  transition: opacity 0.4s;

  @media (max-width: 950px) {
    visibility: hidden;
    z-index: -999;
  }
`;

function ProjectCard({ title, imgUrl, cardDescription, pageUrl = "/" }) {
  const windowWidth = UseWindowDimensions().width;
  const [showDescription, setShowDescription] = useState(false);

  const triggerShowDescription = () => {
    setShowDescription(true);
  };

  const hideDescription = () => {
    setShowDescription(false);
  };

  const descriptionStyle = {
    opacity: showDescription ? `100%` : `0%`,
  };

  const titleStyle = {
    transform:
      showDescription && windowWidth > 950 ? `translateY(-2rem)` : `none`,
  };

  const readMoreStyle = {
    opacity: showDescription ? `100%` : `0%`,
  };

  const cardImgStyle = {
    filter:
      showDescription && windowWidth > 950
        ? `brightness(65%)`
        : `brightness(60%)`,
    position: showDescription && windowWidth > 950 ? `relative` : `static`,
    transform: showDescription && windowWidth > 950 ? `scale(1.05)` : ``,
    boxShadow:
      showDescription && windowWidth > 950
        ? `rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px`
        : `rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px`,
  };

  return (
    <Link to={pageUrl}>
      <CardContainerDiv
        onMouseEnter={triggerShowDescription}
        onMouseLeave={hideDescription}
      >
        <CardTitle style={titleStyle}>{title}</CardTitle>
        <CardDescription style={descriptionStyle}>
          {cardDescription}
        </CardDescription>
        <ReadMoreText style={readMoreStyle}>(Click to read more)</ReadMoreText>
        <CardImg style={cardImgStyle} src={imgUrl} alt="" />
      </CardContainerDiv>
    </Link>
  );
}

export default ProjectCard;
