import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import TechStackCard from "../custom/TechStackCard";
import libraryCardImageYellowBG from "./assets/libraryCardImageYellowBG.jpg";
import libraryAppMockupGifComp from "./assets/coverGifs/libraryAppMockupGifComp.gif";
import firebaseIcon from "./assets/icons/firebaseIcon.png";
import androidIcon from "./assets/icons/androidIcon.png";
import libraryAppStudentHomeImg from "./assets/featureImgs/libraryAppStudentHome.jpg";
import aightMicrobloggingImg from "./assets/aightMicroblogging.jpg";
import aightEventsImg from "./assets/aightEventsImg.jpg";
import "./LibraryAppPage.css";
import FeatureCard from "../custom/FeatureCard";
import ProjectCard from "../custom/ProjectCard";
import Fade from "react-reveal/Fade";

//(props) => props.theme.projectsBackground
const pageBackground = "#1d2025";
const yellowColor = "#ffae42";

const LibraryAppPageContainer = styled.div`
  background-color: ${pageBackground};
  width: 100%;
  transition: 0.3s;
  padding: 5% 0 5% 0;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

  /* width */
  &::-webkit-scrollbar {
    width: 7px;
    background-color: ${(props) => props.theme.resumeBackground};
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 5px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.scrollBar};
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.scrollBarHover};
  }

  @media (max-width: 950px) {
    /* width */
    &::-webkit-scrollbar {
      width: 5px;
    }
  }
`;

const HeadingBar = styled.div`
  background-color: yellow;
  height: 140px;
  width: 8px;
  display: inline-block;
`;

const ProjectHeading = styled.span`
  font-size: 3.6rem;
  width: 200px;
  height: 140px;
  display: inline-block;
  color: ${(props) => props.theme.highEmFc};
  margin-left: 15px;
`;

const LibraryAppMockupImg = styled.img`
  width: 500px;
  border-radius: 20px;
  object-fit: cover;
`;

const IntroPagragraph = styled.p`
  color: ${(props) => props.theme.mediumEmFc};
  margin-top: 7%;
`;

const TechStackTitle = styled.p`
  color: ${(props) => props.theme.highEmFc};
  font-size: 2rem;
  padding: 0 55% 0px 10%;
  margin: 3% 0 10px 0;

  @media (max-width: 950px) {
    margin: 55px 0 10px 0;
    padding: 0 10% 0px 10%;
  }
`;

const TechStackCardContainer = styled.div`
  margin: 10px 30px 10px 0;
`;

const FeatureCardContainer = styled.div`
  margin: 10px 30px 10px 0;

  @media (max-width: 950px) {
    margin: 10px 0 10px 0;
  }
`;

const ProjectGoalTitle = styled.p`
  color: ${(props) => props.theme.highEmFc};
  font-size: 2rem;
  padding: 0 55% 0px 10%;
  margin: 5% 0 10px 0;

  @media (max-width: 950px) {
    margin: 40px 0 10px 0;
    padding: 0 10% 0px 10%;
  }
`;

const ProjectGoalText = styled.p`
  color: ${(props) => props.theme.mediumEmFc};
  margin: 20px 30% 0 10%;

  @media (max-width: 950px) {
    margin: 10px 10% 0 10%;
  }
`;

const ThoughtProcessTitle = styled.p`
  color: ${(props) => props.theme.highEmFc};
  font-size: 2rem;
  padding: 0 55% 0 10%;
  margin: 5% 0 10px 0;
`;

const FeaturesTitle = styled.p`
  color: ${(props) => props.theme.highEmFc};
  font-size: 2rem;
  padding: 0 55% 0px 10%;
  margin: 5% 0 10px 0;

  @media (max-width: 950px) {
    margin: 50px 0 0 0;
    padding: 0 10% 0px 10%;
  }
`;

const techStack = [
  { icon: androidIcon, name: "Android java" },
  { icon: firebaseIcon, name: "Firebase" },
];

function getTechStackCard(item, key) {
  return (
    <TechStackCardContainer key={key}>
      <TechStackCard name={item.name} icon={item.icon} key={key} />
    </TechStackCardContainer>
  );
}

const features = [
  {
    img: libraryAppStudentHomeImg,
    title: "Borrow/return a book with a click",
    description:
      "Students choose a book that they want to borrow or return. The app generates a unique QR code which is then scanned by the librarian, who chooses a return date for the book, and everything else is taken care of and automatically recorded.",
  },
  {
    img: libraryAppStudentHomeImg,
    title: "Add and modify books",
    description:
      "Librarians can modify an existing book (its description, total count, author, etc) and also add new books that are available for the students to borrow.",
  },
  {
    img: libraryAppStudentHomeImg,
    title: "Check book availability",
    description:
      "Students can search and find out if the desired book is available in the library",
  },
];

function getFeatureCard(item, key) {
  return (
    <FeatureCardContainer key={key}>
      <FeatureCard
        img={item.img}
        title={item.title}
        description={item.description}
        key={key}
      />
    </FeatureCardContainer>
  );
}

const ShowMoreFeaturesButton = styled.button`
  margin: 10px 0 0 10%;
  color: ${yellowColor};
  background-color: ${pageBackground};
  height: 70px;
  padding: 0 20px;
  border-radius: 10px;
  font-size: 1.2rem;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  outline: none;
  border: solid 1px ${yellowColor};

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 950px) {
    margin: 20px auto 0 auto;
    font-size: 1rem;
    display: block;
  }
`;

const LessonsLearntTitle = styled.p`
  color: ${(props) => props.theme.highEmFc};
  font-size: 2rem;
  padding: 0 55% 0px 10%;
  margin: 5% 0 10px 0;

  @media (max-width: 950px) {
    margin: 40px 0 0 0;
    padding: 0 10% 0px 10%;
  }
`;

const LessonsLearntText = styled.p`
  color: ${(props) => props.theme.mediumEmFc};
  margin: 20px 30% 0 10%;

  @media (max-width: 950px) {
    margin: 10px 10% 0 10%;
  }
`;

const OtherProjectsTitle = styled.p`
  color: ${(props) => props.theme.highEmFc};
  font-size: 2rem;
  padding: 0 55% 0px 10%;
  margin: 5% 0 10px 0;

  @media (max-width: 950px) {
    margin: 40px 0 10px 0;
    padding: 0 10% 0px 10%;
  }
`;

const OtherProjectsContainer = styled.div`
  margin-right: 10%;
  margin-left: 10%;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */

  @media (max-width: 950px) {
    margin-top: 20px;
    margin-left: 5%;
    margin-right: 5%;
    font-size: 1rem;
    justify-content: center;
  }
`;

const OtherProjectCardContainer = styled.div`
  margin: 20px 40px 30px 0;

  &:hover {
    cursor: pointer;
  }
  @media (max-width: 950px) {
    margin-left: 5%;
    margin-right: 5%;
    font-size: 1rem;
    justify-content: center;
    margin: 0 10px 20px 0;
  }
`;

const projects = [
  {
    title: "Events website",
    imgUrl: aightEventsImg,
    cardDescription:
      "Helping students discover events in colleges around them.",
    pageUrl: "/events-website",
  },
  {
    title: "Microblogging App",
    imgUrl: aightMicrobloggingImg,
    cardDescription:
      "Users can post micro-blogs and look at other people's blogs.",
    pageUrl: "/microblogging-app",
  },
];

const BackToHomeButton = styled.button`
  margin: 10px 0 0 10%;
  color: ${yellowColor};
  background-color: ${pageBackground};
  height: 70px;
  padding: 0 20px;
  border-radius: 10px;
  font-size: 1.2rem;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  outline: none;
  border: solid 1px ${yellowColor};

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 950px) {
    margin: 20px auto 0 auto;
    font-size: 1rem;
    display: block;
  }
`;

function LibraryAppPage(props) {
  const [showMoreFeatures, setShowMoreFeatures] = useState(false);

  function updateFeatures() {
    if (showMoreFeatures == false) {
      setShowMoreFeatures(true);
    } else {
      setShowMoreFeatures(false);
    }
  }

  function createProjectItem(project, key) {
    return (
      <OtherProjectCardContainer key={key}>
        <ProjectCard
          title={project.title}
          imgUrl={project.imgUrl}
          cardDescription={project.cardDescription}
          pageUrl={project.pageUrl}
          key={key}
        />
      </OtherProjectCardContainer>
    );
  }
  return (
    <LibraryAppPageContainer>
      <div className="library-app-page-head">
        <div className="library-app-page-heading-container">
          <Fade left>
            <div className="library-app-page-title-container">
              <HeadingBar />
              <ProjectHeading>Library App</ProjectHeading>
            </div>
            <IntroPagragraph>
              This was for a class project. We decided to create an app that
              would help librarians manage borrowed/returned books and also make
              the process more simpler. This would also help the students know
              what books are available (info about them) without visiting the
              library.
            </IntroPagragraph>
          </Fade>
        </div>
        <Fade right>
          <div className="library-app-page-mockup-conatiner">
            <LibraryAppMockupImg
              src={libraryAppMockupGifComp}
              alt="Library app mockup"
            />
          </div>
        </Fade>
      </div>
      <Fade left>
        <TechStackTitle>Tech Stack</TechStackTitle>
        <div className="library-app-tech-stack-container">
          {techStack.map(getTechStackCard)}
        </div>
        <ProjectGoalTitle>Project Goal</ProjectGoalTitle>
        <ProjectGoalText>
          As students ourselves we found it tedious to visit the library to find
          out what books were available and so the idea for our class project
          clicked. The goal was to solve this problem in the given timeframe of
          over 3 weeks.
        </ProjectGoalText>
        {/* <ThoughtProcessTitle>Thought Process</ThoughtProcessTitle> */}
        <FeaturesTitle>Features</FeaturesTitle>
        <div className="library-app-features-container">
          {showMoreFeatures
            ? features.map(getFeatureCard)
            : features.slice(0, 2).map(getFeatureCard)}
        </div>
        <ShowMoreFeaturesButton onClick={updateFeatures}>
          {showMoreFeatures ? "Hide extra" : "Show More"}
        </ShowMoreFeaturesButton>
        <LessonsLearntTitle>Lessons Learnt</LessonsLearntTitle>
        <LessonsLearntText>
          There were a few challenges we ran into, but in the end, we figured it
          out. Our understanding of fragments and how the back stack works
          deepened. Our design skills were improved. We also worked on making
          custom list views and custom components so that we would be able to
          customize the components to our liking.
          <br />
          <br />
          It was a challenging (but interesting) task to make 2 UI's in a single
          app and display each one based on who logged in. And we managed to
          make it way better than we first hoped.
        </LessonsLearntText>
        <OtherProjectsTitle>Other Projects</OtherProjectsTitle>
        <OtherProjectsContainer>
          {projects.map(createProjectItem)}
        </OtherProjectsContainer>
        <Link style={{ textDecoration: "none" }} to="/">
          <BackToHomeButton>
            <i class="fas fa-arrow-left"></i> {"   "}Back to homepage
          </BackToHomeButton>
        </Link>
      </Fade>
    </LibraryAppPageContainer>
  );
}

export default LibraryAppPage;
