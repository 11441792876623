import React from "react";
import styled from "styled-components";

const UnderDevHeading = styled.h1`
  color: white;
  text-align: center;
`;

function UnderDevelopment() {
  return <UnderDevHeading>Page Under Development</UnderDevHeading>;
}

export default UnderDevelopment;
